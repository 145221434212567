import { createBrowserRouter } from 'react-router-dom'

import Root from './page/Root'
import Index from './page/Index'
import Event from './page/Event'
import EmailVerification from './page/EmailVerification'
import NotFound from './page/NotFound'
import Redeemed from './page/Redeemed'

export default createBrowserRouter([
    {
        path: '*',
        name: 'notFound',
        element: <NotFound />,
    },

    {
        path: '/:id/:lang/:event',
        name: 'index',
        element: <Index />,
    },
    {
        path: '/:id/:lang/:event',
        element: <Root />,
        children: [
            // {
            //     index: true,
            //     name: 'reminder',
            //     element: <Reminder />,
            // },
            {
                path: '/:id/:lang/:event/event',
                name: 'event',
                element: <Event />,
            },
            {
                path: '/:id/:lang/:event/email_verification',
                name: 'email_verification',
                element: <EmailVerification />,
            },
        ]
    },
    {
        path: '/:id/:lang/:event/redeemed',
        name: 'redeemed',
        element: <Redeemed />,
    }
])
