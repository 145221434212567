import i18n from 'i18next'
import { initReactI18next } from 'react-i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';

//  locales
import * as resources from './locales/*.js'

i18n
    .use(intervalPlural)
    .use(initReactI18next)
    .init({
        compatibilityJSON: 'v3',
        fallbackLng: 'en',
        lng: 'en',
        resources,
        interpolation: {
            escapeValue: false,
        },
        ns: ['common'],
        defaultNS: 'common',
    });

export default i18n
