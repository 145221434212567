import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { Card, TextField, MenuItem, CardContent, Button, FormControlLabel, Checkbox, Modal, Grid, FormControl, FormLabel, FormGroup } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { forEach, map, startCase, get, includes } from 'lodash'
import { formSubmit } from '../../utils/apiService'

export default function Unilever() {
    const [agreement, setAgreement] = useState(false)
    const [privacy_notice, setPrivacyNotice] = useState(false)
    const [terms_of_use, setTermsOfUse] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errors, setErrors] = useState([])
    const { t } = useTranslation('unilever')
    const { register, handleSubmit } = useForm()
    const navigate = useNavigate()
    const { id } = useParams()
    const options = {
        skin_tone: [
            { name: t('dry'), value: 'dry' },
            { name: t('oily'), value: 'oily' },
            { name: t('combination'), value: 'combination' },
        ],
        skin_concern: [
            { name: t('skin_concern_options.acne'), value: 'acne' },
            { name: t('skin_concern_options.dryness'), value: 'dryness' },
            { name: t('skin_concern_options.dull_skin'), value: 'dull_skin' },
            { name: t('skin_concern_options.fine_lines'), value: 'fine_lines' },
            { name: t('skin_concern_options.oiliness'), value: 'oiliness' },
            { name: t('skin_concern_options.pigmentation'), value: 'pigmentation' },
            { name: t('skin_concern_options.uneven_skin'), value: 'uneven_skin' },
            { name: t('skin_concern_options.sensitivity'), value: 'sensitivity' },
            { name: t('skin_concern_options.lack_of_firmness'), value: 'lack_of_firmness' },
        ],
        skin_type: [
            { name: t('skin_type_options.acne'), value: 'acne' },
            { name: t('skin_type_options.dryness'), value: 'dryness' },
            { name: t('skin_type_options.dull_skin'), value: 'dull_skin' },
            { name: t('skin_type_options.sensitivity'), value: 'sensitivity' },
            { name: t('skin_type_options.fine_lines'), value: 'fine_lines' },
        ],
            
        interested_product_type: [
            { name: t('facial_wash'), value: 'facial_wash' },
            { name: t('toner'), value: 'toner' },
            { name: t('serum'), value: 'serum' },
            { name: t('moisturizer'), value: 'moisturizer' },
            { name: t('lip_care'), value: 'lip_care' },
            { name: t('sunscreen'), value: 'sunscreen' },
            { name: t('sheet_mask'), value: 'sheet_mask' },
            { name: t('hair_styling'), value: 'hair_styling' },
        ]   
    }

    const onSubmit = async (data) => {
        setLoading(true)
        let validate = true
        let fieldErrors = []
        forEach(data, (value, key) => {
            console.log('key', key, 'value', value, 'data', data, 'options', options, 't', t('invalid_email'), 't', t('invalid_mobile'), 't', t('invalid_age'), 't', t('work_email_required'), 't', t('field_required', { field: startCase(key) }))
            switch (key) {
                case 'email':
                    const regForEmail = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/
                    if (!regForEmail.test(value)) {
                        validate = false
                        fieldErrors.push(t('invalid_email'))
                    }

                    if (includes(value, 'gmail') || includes(value, 'hotmail')) {
                        validate = false
                        fieldErrors.push(t('work_email_required'))
                    }
                    break
                case 'mobile':
                    const regForMobile = /^\d+$/
                    if (!regForMobile.test(value)) {
                        validate = false
                        fieldErrors.push(t('invalid_mobile'))
                    }
                    break
                case 'age':
                    if (parseInt(value) <= 0 && parseInt(value) > 200 || !value) {
                        validate = false
                        fieldErrors.push(t('invalid_age'))
                    }
                    break
                default:
                    if (!value) {
                        validate = false
                        fieldErrors.push(t('field_required', { field: startCase(key) }))
                    }
            }
        })

        if (!validate) {
            setErrors(fieldErrors)
            setLoading(false)
            return
        }

        try {
            const result = await formSubmit(id, {
                ...data,
                opt_in_for_privacy_notice: true,
                opt_in_for_terms_of_use: true,
                opt_in_for_marketing: true,
            })
        } catch (error) {
            let tempError = []
            forEach(get(error, 'response.data.data', {}), (value, key) => {
                tempError = [
                    ...tempError,
                    ...value,
                ]
            })

            setErrors([
                ...errors,
                ...tempError,
            ])
            return
        } finally {
            setLoading(false)
        }
        
        // navigate(`email_verification`)
        // navigate to route by name 'email_verification'
        navigate(`../email_verification`)
    }

    return (
        <div className="w-full h-auto mx-auto align-middle p-4 container">
            <Card className="h-fit">
                <CardContent className="h-fit flex flex-col">

                    <div className="w-100 mx-auto text-center text-3xl mb-6">{ t('title') }</div>
                    <div className="w-9/12 mx-auto text-center">
                        <ol className="list-decimal text-left">
                            <li>Gmail & Hotmail registrations are not allowed, kindly use WORK email for redemption</li>
                            <li>1 Redemption Per User Per Month</li>
                        </ol>
                    </div>
                    <div className="flex-1">
                        <form className="flex flex-col h-full" noValidate onSubmit={handleSubmit(onSubmit)}>
                            <TextField
                                margin="normal"
                                id="first_name"
                                name="first_name"
                                label={t('first_name')}
                                {...register('first_name')}
                            />

                            <TextField
                                margin="normal"
                                id="last_name"
                                name="last_name"
                                label={t('last_name')}
                                {...register('last_name')}
                            />

                            <TextField
                                margin="normal"
                                id="email"
                                name="email"
                                label={t('email')}
                                {...register('email')}
                            />

                            <TextField
                                margin="normal"
                                id="mobile"
                                name="mobile"
                                label={t('mobile')}
                                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                {...register('mobile')}
                            />

                            <TextField
                                margin="normal"
                                id="age"
                                name="age"
                                label={t('age')}
                                type="number"
                                {...register('age')}
                            />
                            <FormControl component="fieldset" sx={{my: 3}} >
                                {t('skin_concern')}
                                <FormGroup sx={{ mx: 3 }} >
                                    { map(options.skin_concern, (option, index) => 
                                        <FormControlLabel 
                                            key={'skin_concern_option_'+index}
                                            control={
                                                <Checkbox 
                                                    value={option.value}
                                                    {...register('skin_concern', {required: true})}
                                                />} 
                                            label={option.name} 
                                        />
                                    )}
                                </FormGroup>
                            </FormControl>
                            <TextField
                                style={{whiteSpace: 'normal'}}
                                margin="normal"
                                id="skin_type"
                                name="skin_type"
                                label={t('skin_type')}
                                select
                                {...register('skin_type')}
                            >
                                { map(options.skin_type, (option, index) => <MenuItem key={'skin_type_option_'+index} value={option.value} style={{whiteSpace: 'normal'}}>{option.name}</MenuItem>) }
                            </TextField>

                            {/* <TextField
                                margin="normal"
                                id="skin_concern"
                                name="skin_concern"
                                label={t('skin_concern')}
                                select
                                {...register('skin_concern')}
                            >
                                { map(options.skin_concern, (option, index) => <MenuItem  key={'skin_concern_option_'+index} value={option.value}>{option.name}</MenuItem>) }
                            </TextField> */}

                            <FormControl component="fieldset" sx={{my: 3}} >
                                {t('interested_products')}
                                <FormGroup sx={{ mx: 3 }} >
                                    { map(options.interested_product_type, (option, index) => 
                                        <FormControlLabel 
                                            key={'interested_product_type-'+index}
                                            control={
                                                <Checkbox 
                                                    value={option.value}
                                                    {...register('products_interested', {required: true})}
                                                />} 
                                            label={option.name} 
                                        />
                                    )}
                                </FormGroup>
                            </FormControl>

                            <FormControlLabel
                                margin="normal"
                                label={<span dangerouslySetInnerHTML={{__html: t('privacy_notice')}}/>}

                                control={
                                    <Checkbox
                                        checked={privacy_notice}
                                        onChange={(e) => setPrivacyNotice(e.target.checked)}
                                        name="privacy_notice"
                                        color="primary"
                                    />
                                }
                            />
                            <FormControlLabel
                                margin="normal"
                                label={<span dangerouslySetInnerHTML={{__html: t('terms_of_use')}}/>}

                                control={
                                    <Checkbox
                                        checked={terms_of_use}
                                        onChange={(e) => setTermsOfUse(e.target.checked)}
                                        name="terms_of_use"
                                        color="primary"
                                    />
                                }
                            />
                            <FormControlLabel
                                margin="normal"
                                label={<span dangerouslySetInnerHTML={{__html: t('marketing_agreement')}}/>}
                                control={
                                    <Checkbox
                                        checked={agreement}
                                        onChange={(e) => setAgreement(e.target.checked)}
                                        name="agreement"
                                        color="primary"
                                    />
                                }
                            />

                            <div className="mt-6 w-full">
                                <LoadingButton type="submit" variant="contained" disabled={!agreement || !privacy_notice || !terms_of_use} loading={loading} className="w-full">{ t('submit') }</LoadingButton>
                            </div>
                        </form>
                    </div>
                </CardContent>
            </Card>
            <Modal open={errors.length > 0}>
                <Card className="w-4/5 mx-auto absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"> 
                    <CardContent className="w-100 mx-auto align-middle">
                        <div>
                            { map(errors, (error) => <Grid sx={{ py: 1 }} xs={12} spacing={12}>{error}</Grid>) }
                            <div className="mt-12 flex justify-end">
                                <Button onClick={() => setErrors([])}>{ t('cancel') }</Button>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </Modal>
        </div>
    )
}
