import { useState, useEffect } from 'react'
import { Outlet, useParams, useNavigate } from 'react-router-dom'
import { getStatus } from '../utils/apiService'
import { useDispatch, useSelector } from 'react-redux'
import { unileverRedeemed } from '../redux/actions/unilever'
import { CircularProgress } from '@mui/material';

export default function Root() {
    const [loading, setLoading] = useState(true)
    const { id, lang, event } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        const getQuestionnaireStatus = async () => {
            try {
                const { data } = await getStatus(id)

                switch (data.status) {
                    case 0:
                        break
                    case 1:
                        dispatch(unileverRedeemed()) // set redeemed to true
                        navigate(`redeemed`)
                        break
                    case 2:
                        navigate(`email_verification`)
                        break
                    default:
                        navigate(`notFound`)
                }
            } catch (error) {
                navigate(`notFound`)
            } finally {
                setLoading(false)
            }
        }

        getQuestionnaireStatus()
    }, [])

    if (loading) {
        return (
            <div className="w-screen h-screen">
                <div className="absolute top-1/2 left-1/2 h-fit w-fit -translate-x-1/2 -translate-y-1/2">
                    <CircularProgress size={80} />
                </div>
            </div>
        )
    }

    return (
        <>
            <img src='/TGC_logo.png' className='w-1/4 my-0 mx-auto'/>
            <Outlet />
        </>
    )
}
