export default {
    common: {},
    unilever: {
        title: 'Tell us more about you!',
        first_name: 'First Name',
        last_name: 'Last Name',
        name: 'Name',
        email: 'Work Email',
        mobile: 'Mobile Number',
        age: 'Age',
        skin_type: 'Your skin type',
        skin_concern: "What's your top skin concerns?",
        agreement: 'Agree to receive marketing e.g. email/whatsapp/sms (must select yes to redeem samples)',
        submit: 'Submit',

        dry: 'Dry',
        oily: 'Oily',
        combination: 'Combination',
        dryness: 'Dryness',
        oily_skin: 'Oily Skin',
        sagging: 'Sagging',
        wrinkles: 'Wrinkles',
        skin_dullness: 'Skin Dullness',
        invalid_age: 'Invalid age',
        invalid_mobile: 'Invalid mobile',
        invalid_email: 'Invalid email',
        field_required: '{{field}} is required',
        cancel: 'Cancel',
    },
    emailVerification: {
        title: 'Enter the OTP in your mobile',
        resend: 'Resend',
        resend_counter: 'Resend({{count}})'
    },
    redeemed: {
        redeemed: 'Redeemed!',
        congratulations: 'Congratulations!',
        title: 'Your sample is ready for collection!!',

        description: {
            line1: 'Use code',
            line2: 'to enjoy 10% discount when you repurchase the product on',
            line3: 'Thank you!',
        },        back: 'Back to home',
    }
}

