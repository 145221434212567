import { useEvent } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'

//  Components
import Unilever from '../components/Event/Unilever'

export default function Event() {
    const { id, lang, event } = useParams()

    switch (event) {
        case 'unilever':
            return (<Unilever props={{ id, lang, event }}/>)
    }
}

