import React from "react";
import { useTranslation } from 'react-i18next'
import { Card, CardContent, Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'

export default function Index() {
    const { t } = useTranslation('index')
    const navigate = useNavigate()

  return (
    <div className="w-100 h-auto mx-auto align-middle p-4 container">
        <Card className="h-full">
            <CardContent className="flex flex-col justify-center self-center h-full p-4 text-xl text-center overflow-auto">
                <img src="/TGC_logo.png" className="mx-auto my-0 w-12/12"/>
                <div className="font-bold" style={{color: "rgb(108, 190, 76)"}}> 
                  {/* loop the t('reminder') and output the sentences in the array */}
                  {t('reminder', { returnObjects: true }).map((sentence, index) => (
                    <>
                      <span key={index}>{sentence}</span><br/><br/>
                    </>
                  ))}
                </div>
                <br/>
                <Button variant="contained" onClick={()=>navigate('event')} style={{backgroundColor: "rgb(40, 183, 241)"}}>{t('continue')}</Button>
            </CardContent>
        </Card>
    </div>
  );
}