export default {
    common: {},
    index: {
        reminder: [
            'Do not close the QR code on the vending machine\'s screen until your sample is dispensed, if not the sample will not dispense.',
            'One redemption is allowed at a time, so please wait in line.',
            'Every redemption requires a unique QR code, so do not scan the same code twice.',
        ],
        continue: 'Continue',
    },
    unilever: {
        title: 'Tell us more about you!',
        first_name: 'First Name',
        last_name: 'Last Name',
        name: 'Name',
        email: 'Work Email',
        mobile: 'Mobile Number',
        age: 'Age',
        skin_type: 'Which of the following best describes your skin?',
        skin_concern: "What are some of your main skin concerns? Choose as many as you like.",
        interested_products: 'What type of products are you interested to sample before purchasing? Choose as many as you like.',
        privacy_notice: '*I am 18+ years old. I have read and understood the terms of this <a class="underline text-blue-400 cursor-pointer" href="https://www.unilevernotices.com/privacy-notices/singapore-english.html" target="_blank">Privacy Notice</a> and consent to the processing of my personal data for the purposes stated therein.',
        terms_of_use: '*I have read and understood the <a class="underline text-blue-400 cursor-pointer" href="https://thegoodcart.com/pages/terms-of-use" target="_blank">Terms of Use</a> and <a class="underline text-blue-400 cursor-pointer" href="https://thegoodcart.com/pages/terms-of-sale" target="_blank">Terms of Sale</a> governing my use of this website and the items I will be ordering from this website.',
        marketing_agreement: '*Yes, I want to receive offers and updates regarding The Good Cart website and selected <a class="underline text-blue-400 cursor-pointer" href="https://www.unilever.com/brands" target="_blank">Unilever Brands</a> electronically (including by email and Whatsapp) and via online advertising tailored to my interests and preferences.',
        submit: 'Submit',

        skin_concern_options: {
            acne: 'Acne',
            dryness: 'Dryness',
            dull_skin: 'Dull Skin/Lack of Radiance',
            fine_lines: 'Fine Lines or Wrinkles',
            oiliness: 'Oiliness',
            pigmentation: 'Pigmentation/Dark Spots',
            uneven_skin: 'Uneven Skin Tone',
            sensitivity: 'Sensitivity/Redness',
            lack_of_firmness: 'Lack of Firmness',        
        },

        facial_wash: 'Facial Wash',
        toner: 'Toner',
        serum: 'Serum',
        moisturizer: 'Moisturizer',
        lip_care: 'Lip Care',
        sunscreen: 'Sunscreen',
        sheet_mask: 'Sheet Mask',
        hair_styling: 'Hair Styling',

        skin_type_options: {
            acne: 'I frequently experience breakouts, and have pore problems, I can sometimes feel oily residue when I touch my skin.',
            dryness: 'My skin frequently feels flaky, tight or itchy, and lacks radiance.',
            dull_skin: 'My skin lacks radiance and appear ashy. Skin tone is uneven and I look fatigued.',
            sensitivity: 'I experience frequent redness or irritation. My skin can also be very reactive to new products or certain ingredients.',
            fine_lines: 'My skin has fine lines and is less firm than before. I might also have pigmentations like sun spots.',
        },

        
        invalid_age: 'Invalid age',
        invalid_mobile: 'Invalid mobile',
        invalid_email: 'Invalid email',
        work_email_required: 'Sorry, only work email is allowed',
        field_required: '{{field}} is required',
        cancel: 'Cancel',
    },
    emailVerification: {
        title: 'Enter the OTP in your mobile',
        resend: 'Resend',
        resend_counter: 'Resend({{count}})'
    },
    redeemed: {
        redeemed: 'Redeemed!',
        congratulations: 'Congratulations!',
        title: 'Your sample is ready for collection!!',
        // description: 'Your samples will be delivered to you within 2 weeks.',
        description: {
            line1: 'Use code',
            line2: 'to enjoy 10% discount when you repurchase the product on',
            line3: 'Thank you!',
        },
        back: 'Back to home',
    }
}

