import React from 'react';
import { Box, Button, Container, Typography, Grid } from '@mui/material';

export default function Error () {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                padding: '3rem',
            }}
        >
            <Container maxWidth="md">
                <Grid container spacing={2}>
                    <Grid xs={12} md={6} order={{ xs: 2, md: 1 }}>
                        <Typography variant="h1">
                            404
                        </Typography>
                        <Typography variant="h6">
                            The page you’re looking for doesn’t exist.
                        </Typography>
                    </Grid>
                    <Grid xs={12} md={6} order={{ xs: 1, md: 2 }}>
                        <img
                            src="https://cdn.pixabay.com/photo/2017/03/09/12/31/error-2129569__340.jpg"
                            alt=""
                            width={500} height={250}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
