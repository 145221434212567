import { useState, useEffect } from 'react'
import VerificationInput from 'react-verification-input'
import { Card, CardContent, Button } from '@mui/material'
import { emailVerification, resendVerificationCode } from '../utils/apiService'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { get } from 'lodash'

export default function EmailVerification () {
    const { t } = useTranslation('emailVerification')
    const navigate = useNavigate()
    const [code, setCode] = useState('')
    const [loading, setLoading] = useState(false)
    const [resendTimer, setResendTimer] = useState(60)
    const [error, setError] = useState('')
    const { id } = useParams()

    const inputComplete = async (data) => {
        setLoading(true)
        try {
            await emailVerification(id, { verification_code: data })
        } catch (error) {
            // setError('Something went wrong')
            setError(error?.response?.data?.message)
            return
        } finally {
            setLoading(false)
        }

        navigate(`../redeemed`)
    }

    const resendEmailVerificationCode = async () => {
        setResendTimer(60)
        try {
            await resendVerificationCode(id)
        } catch (error) {
            setError('Something went wrong')
            return
        }
    }

    // resend timer
    useEffect(() => {
        const timer = setTimeout(() => {
            if (resendTimer == 0) return
            setResendTimer(resendTimer - 1)
        }, 1000)

        return () => clearTimeout(timer)
    }, [resendTimer])

    return (
        <div className="w-100 h-auto mx-auto align-middle p-4 container">
            <Card className="h-full">
                <CardContent className="flex justify-center self-center flex-col h-full">
                    <div className="text-center text-3xl mb-4">{ t('title') }</div>
                    <div className="flex-1 flex flex-col justify-center self-center">
                        { error && <div className="text-red-500 text-center mb-4">{error}</div> }
                        <div className=" self-center">
                            <VerificationInput 
                                length={6}
                                placeholder={' '}
                                inputProps={{ autoComplete: 'one-time-code' }}
                                onChange={(value) => setCode(value)}
                                onComplete={inputComplete}
                            />
                        </div>

                        <Button disabled={resendTimer !== 0} onClick={resendEmailVerificationCode}>
                            {
                                resendTimer === 0 ?
                                    t('resend', { count: resendTimer }) :
                                    t('resend_counter', { count: resendTimer })
                            }
                        </Button>
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}
