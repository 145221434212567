import * as type from '../types'

const initialState = {
    redeemed: false,
}

export default (state = initialState, action) => {
    switch (action) {
        case type.UNILEVER_REDEEMED:
            return {
                ...state,
                redeemed: true,
            }
        default:
            return { ...state }
    }
}
