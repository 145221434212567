import { useTranslation } from 'react-i18next'
import { Card, CardContent } from '@mui/material'

export default function Redeemed () {
    const { t } = useTranslation('redeemed')

    return (
        <div className="w-100 h-auto mx-auto align-middle p-4 container">
            <Card className="h-full">
                <CardContent className="flex flex-col justify-center self-center h-full p-4 text-xl text-center overflow-auto">
                    <img src="/TGC_logo.png" className="mx-auto my-0 w-12/12"/>
                    <span>{ t("title") }</span><br />
                    <span>{ t("description.line1") }</span>
                    <span className="text-3xl text-red-600 font-bold">vendingsample10</span>
                    <span>{ t('description.line2') }</span>
                    <a href="https://thegoodcart.com/" className="text-blue-600 underline">https://thegoodcart.com/</a>
                    <br/>
                    <span>{ t('description.line3') }</span>
                </CardContent>
            </Card>
        </div>
    )
}
